import React from "react";
import MoreInformation from "../components/Trips/MoreInformation";
import { Trans } from "react-i18next";
import { presentationTrips } from "../components/Trips/trips-data";

const tripInformation = presentationTrips.find(
  (x) => x.id === "formentor-beach-trip"
);

const FormentorBeach = () => {
  return (
    <MoreInformation
      title="Formentor beach"
      description="Enjoy this 25 minutes boat trip from Puerto Pollensa to Formentor, the views of the bay and getting a closer look at the spectacular lighthouse at Cap de Formentor. You will also have the chance to see the famous property La Fortaleza which has appeared in numerous tv series and films as it is one of Spain’s most impressive estates."
      tripInformation={tripInformation}
      timeTable={
        <>
          <Trans i18nKey={tripInformation.moreInformation.timetable}>
            <ul>
              <li>Item 0</li>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
              <li>Item 5</li>
            </ul>
            <strong>Item</strong>
          </Trans>
        </>
      }
    />
  );
};

export default FormentorBeach;
